<template>
	<div class="my-account loaded">
		<b-container class="content intro" fluid>
			<b-row>
				<b-col>
					<h1>Account Information</h1>
					<h4>If you need to update your account information, please click “Change Email”, “Change Phone”, or “Change Password”, and save your changes.</h4>
				</b-col>
			</b-row>
		</b-container>
		<b-container class="content body" fluid>
			<b-row>
				<b-col>
					<b-form class="mb-4" @submit.prevent="submit">
						<transition name="fade" mode="out-in">
							<h4 v-if="saved" class="pt-4 pb-5">Your changes have been saved.</h4>
							<div v-else>
								<b-alert :show="!!error" variant="warning">{{error}}</b-alert>
								<b-form-group
									label="Email"
									label-for="email"
								>
									<b-form-input id="email" ref="email" v-model="email" name="email" type="email" :disabled="!editingEmail" autocomplete="email" autofill="email"></b-form-input>
									<a v-if="!editingEmail" href="#" class="mt-1 fs-14 float-right" @click.prevent="editEmail">Change Email</a>
								</b-form-group>
								<b-form-group
									label="Phone"
									label-for="phone"
								>
									<b-form-input id="phone" ref="phone" v-model="mobile" name="phone" type="tel" :disabled="!editingPhone" autocomplete="tel" autofill="tel"></b-form-input>
									<a v-if="!editingPhone" href="#" class="mt-1 fs-14 float-right" @click.prevent="editPhone">Change Phone</a>
								</b-form-group>
								<b-form-group
									v-if="!editingPassword"
									label="Password"
									label-for="password1"
								>
									<b-form-input id="password1" ref="password1" v-model="password" type="password" disabled></b-form-input>
									<a href="#" class="mt-1 fs-14 float-right" @click.prevent="editingPassword = true">Change Password</a>
								</b-form-group>
								<template v-if="editingPassword">
									<password-form-group :password.sync="passwordConfirm" autofocus label="Old Password" />
									<password-form-group :password.sync="passwordNew" label="New Password" is-new />
								</template>
							</div>
						</transition>
						<div class="d-flex form-controls">
							<btn-back v-if="disabled" />
							<b-button v-else variant="outline-primary" @click="cancel">Cancel</b-button>
							<b-button :disabled="disabled" type="submit" class="ml-auto">Save</b-button>
						</div>
					</b-form>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>

<script>
import PasswordFormGroup from "../components/ui/PasswordFormGroup.vue"

export default {
	name: 'MyAccount',
	components: { PasswordFormGroup },
	data() {
		return {
			email: '',
			password: '',
			mobile: '',
			passwordConfirm: '',
			passwordNew: '',
			editingEmail: false,
			editingPassword: false,
			editingPhone: false,
			saved: false,
			error: ''
		}
	},
	computed: {
		disabled() {
			return !this.editingEmail && !this.editingPassword && !this.editingPhone
		}
	},
	created() {
		this.$store.dispatch('login/loadDetails').then(() => {
			({email: this.email, mobile: this.mobile, password: this.password} = this.$store.state.login)
		})
	},
	methods: {
		editPassword() {
			this.editingPassword = true
		},
		editPhone() {
			this.editingPhone = true
			this.focus('phone')
		},
		editEmail() {
			this.editingEmail = true
			this.focus('email')
		},
		submit() {
			this.error = ''
			const {email, mobile, passwordNew, passwordConfirm} = this; const l = this.$store.state.login
			if (email != l.email || mobile != l.mobile || passwordNew) {
				const o = {email, mobile, newPassword: passwordNew, oldPassword: passwordConfirm}

				o.hasEmailChanged = email != l.email
				o.hasMobileChanged = mobile != l.mobile
				o.hasPasswordChanged = Boolean(passwordNew && passwordNew != l.password)
				this.$http
					.put(this.$store.getters.apiDisclosurePath + 'account-details', o)
					.then(r => {
						this.$store.commit('login/set', {email, mobile})
						if (passwordNew) this.$store.commit('login/set', {password: passwordNew})
						this.showSaved()
					})
					.catch(e => {
						this.error = e.response?.data?.ErrorMessage || 'There was a problem saving the changes. Please check your details and try again.'
					})
			} else {
				this.showSaved()
			}
		},
		cancel() {
			this.editingEmail = this.editingPassword = this.editingPhone = false
		},
		focus(field) {
			this.$nextTick(() => {
				this.$refs[field].focus()
				this.$refs[field].select()
			})
		},
		showSaved() {
			this.saved = true
			setTimeout(() => {
				this.cancel()
				this.saved = false
			}, 2200);
		}
	},
	metaInfo: {
		title: 'My Account',
	}
}
</script>

<style lang="scss">

</style>
